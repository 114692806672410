import { removeOverlay, isOverlay, openPage } from '~/modules/overlay-controller'
import { isLoading } from './loading-indicator'
import { useHydrate, useRefs } from '~/framework'
import quantitySelector from './quantity-selector'
import { Component } from '@/types'
import { debounce } from '@/utils'

async function fetchCartAmount() {
  try {
    const res = await fetch('/cart.js')
    const json = await res.json()
    if (json?.item_count !== undefined) return json.item_count

    throw 'did not receive amount from shopify :('
  } catch (error) {
    window.location.reload()
    return ''
  }
}

export function cartLink(ref) {
  if (!ref.cartLink) return

  ref.cartLink.forEach(link => {
    link.addEventListener('click', async e => {
      e.preventDefault()
      e.stopPropagation()

      openPage(link.href, {
        className: 'cart-overlay',
        pushToNavigationHistory: false,
        type: 'cart',
      })
    })
  })
}

// update text label indicating current amount in cart
export const cartItemCount = ref => {
  if (!ref.cartItemCount) return

  // use markup like:
  // <span data-ref="cart-item-count">{{ cart.item_count }}</span>

  async function updateAmount(e) {
    const amountFromEvent = e.detail?.items?.reduce(
      (acc, cur) => (acc += cur.quantity),
      0
    )
    const amount = amountFromEvent || (await fetchCartAmount())

    ref.cartItemCount.forEach(el => {
      el.textContent = amount

      el.style.display = amount === 0 ? 'none' : 'flex'
    })
  }

  window.addEventListener('se-event.cart.updated', updateAmount)
  window.addEventListener('se-event.cart.product-added', updateAmount)
}

function replaceDom(newDocument, selector) {
  const oldCarts = document.querySelectorAll(selector)
  const newCartContainer = newDocument.querySelector(selector)

  oldCarts.forEach(oldCart => {
    oldCart.after(newCartContainer.cloneNode(true))
    oldCart.remove()
  })

  return newCartContainer
}

async function fetchShopifyPage(
  url,
  { signal = undefined, cache = true } = {}
) {
  const parser = new DOMParser()
  const res = await fetch(`${url}${cache ? '?cache=false' : ''}`, { signal })
  return parser.parseFromString(await res.text(), 'text/html')
}

const debouncedUpdateCartFetch = debounce(async (body, { signal }) => {
  isLoading('cart-form', true)

  await fetch('/cart/update.js', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })

  const newCart = await fetchShopifyPage('/cart', { signal })

  isLoading('cart-form', false)

  return newCart
}, 200)

function updateCart(form) {
  const target = form.tagName !== 'FORM' ? form.querySelector('form') : form

  const cartChangeAbortController = new AbortController()

  return new Promise(resolve => {
    target?.addEventListener(
      'change',
      async e => {
        if (e.target.name === 'updates[]') {
          e.preventDefault()
          e.stopPropagation()

          try {
            // both update cart and get new cart markup back
            const newCartPageDocument = await debouncedUpdateCartFetch({
              updates: { [e.target.id]: parseInt(e.target.value) },
            })

            const newCartContainer = replaceDom(
              newCartPageDocument,
              '.main-cart'
            )

            cartChangeAbortController.abort()
            resolve(newCartContainer)
          } catch (error) {
            isLoading('cart-form', false)
          }
        }
      },
      { signal: cartChangeAbortController.signal }
    )

    const links = target ? [...target.querySelectorAll('a')] : []

    // any/all remove buttons
    links
      .filter(link => link.href.includes('cart/change'))
      .forEach(link => {
        link.addEventListener(
          'click',
          async e => {
            e.preventDefault()
            e.stopPropagation()

            isLoading('cart-form', true)

            // do the removing
            await fetch(link.href)

            const newCart = await fetchShopifyPage('/cart', { cache: false })

            const newCartContainer = replaceDom(newCart, '.main-cart')

            isLoading('cart-form', false)

            cartChangeAbortController.abort()
            resolve(newCartContainer)
          },
          { signal: cartChangeAbortController.signal }
        )
      })
  })
}

async function listenAndUpdateRecursive(form) {
  const newForm = await updateCart(form)

  window.dispatchEvent(new CustomEvent('se-event.cart.updated'))

  listenAndUpdateRecursive(newForm)
}

const component: Component = ref => {
  if (!ref.mainCart) return
  ref.mainCart.map(listenAndUpdateRecursive)
}

export default component

window.addEventListener('se-event.cart.updated', () => {
  useHydrate([component, quantitySelector]).hydrate(useRefs())
})

window.addEventListener('se-event.cart.product-added', () => {
  openPage('/cart', {
    className: 'cart-overlay',
    pushToNavigationHistory: false,
    type: 'cart',
  })
})

window.addEventListener('se-event.product-card.overlay-opened', () => {
  if (isOverlay('/cart')) {
    removeOverlay('/cart')
  }
})
