import { components } from '~/components'
import { isLoading } from '~/components/loading-indicator'
import { useHydrate, useRefs } from '~/framework'
import barba from '@barba/core'
import { state } from '@/utils'

// pages can be fetch but not opened, so we need to separate the two
const overlayPages = new Map()
// export const openedOverlays = new Set()
export const openedOverlays = new Array()

const getKey = url => {
  if (!url) return

  const { origin } = window.location
  const withOrigin = origin + url.replace(origin, '')
  const { pathname } = new URL(withOrigin)

  return pathname
}

export function isOverlay(url) {
  const key = getKey(url)
  return overlayPages.has(key)
}

export function prefetchPage(url: string, type?: string) {
  const key = getKey(url)
  overlayPages.set(key, { page: fetchPage(url), type })
  return overlayPages.get(key)
}

const selector = 'data-content-for-layout'

async function fetchPage(url) {
  const res = await fetch(url)
  const text = await res.text()
  const parser = new DOMParser()
  const fetchedDocument = parser.parseFromString(text, 'text/html')

  const page = fetchedDocument.querySelector(`[${selector}]`) as HTMLElement
  page.classList.add('scroll-area')
  page.removeAttribute(selector)

  const scrollArea = document.createElement('div')
  scrollArea.classList.add('overlay-content')
  scrollArea.append(page)

  const overlay = document.createElement('div')
  overlay.append(scrollArea)

  return overlay
}

function appendAndHydrate(markup) {
  const refs = useRefs({ root: markup })
  useHydrate(components).hydrate(refs)

  document.body.append(markup)

  return markup
}

function createCloseButtonOne(onClick) {
  const closeButtonOne = document.createElement('button')
  closeButtonOne.classList.add('close-button-one')
  closeButtonOne.append(document.createElement('div'))
  closeButtonOne.title = 'close'
  closeButtonOne.addEventListener('click', onClick)

  return closeButtonOne
}

const closeIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.51562 20.4883L20.4862 3.51772" stroke="currentColor" stroke-width="1.36364"/>
  <path d="M3.52344 3.51172L20.494 20.4823" stroke="currentColor" stroke-width="1.36364"/>
</svg>
`

function createCloseButtonTwo(onClick) {
  const closeButtonTwoContainer = document.createElement('div')
  const closeButtonTwo = document.createElement('button')
  closeButtonTwo.insertAdjacentHTML('afterbegin', closeIcon)
  closeButtonTwoContainer.classList.add('close-button-two')
  closeButtonTwo.title = 'close'
  closeButtonTwo.addEventListener('click', onClick)

  closeButtonTwoContainer.append(closeButtonTwo)

  return closeButtonTwoContainer
}

export async function openPage(
  url,
  {
    className = ' ',
    pushToNavigationHistory = true,
    type = 'page',
    isNavigatingBack = false,
  } = {}
): Promise<HTMLElement | void> {
  try {
    if (pushToNavigationHistory || isNavigatingBack) {
      openedOverlays.push(getKey(url))
    }

    if (pushToNavigationHistory) {
      barba.history.add(url, 'barba', 'push', {
        overlay: true,
        type,
        className,
      })
    }

    forEachOverlay(removeOverlay, type)

    isLoading(url, true)

    // get an already registered prefetch, or register a new fetch
    const overlay = await (overlayPages.get(getKey(url)) ||
      prefetchPage(url, type))
    const page = await overlay.page

    if (!overlay.type) {
      overlay.type = type
    }

    isLoading(url, false)

    appendAndHydrate(page)

    page.classList?.add('is-overlay', ...className.split(' '))
    page.dataset.url = getKey(url)

    function handleClose() {
      if (pushToNavigationHistory) {
        let overlays = 0

        for (let i = barba.history.size - 1; i > 0; i--) {
          if (barba.history.get(i).data.overlay) overlays++
        }

        history.go(overlays * -1)
      } else {
        removeOverlay(url)
      }
    }

    const closeButtonOne = createCloseButtonOne(handleClose)
    page.insertAdjacentElement('afterbegin', closeButtonOne)
    const closeButtonTwo = createCloseButtonTwo(handleClose)
    page.insertAdjacentElement('afterbegin', closeButtonTwo)

    return page
  } catch (error) {
    // bail big-time and just hard refresh on error
    // window.location.href = card.href
    console.warn(error)
  }
}

export function closeAllOverlays() {
  const overlays = document.querySelectorAll('.is-overlay')

  if (!overlays.length) return false

  overlays.forEach(overlay => {
    overlay.classList.add('is-closing')
    setTimeout(() => overlay.remove(), 500)
  })

  overlayPages.clear()

  return true
}

export async function removeOverlay(url) {
  // const overlay = overlayPages.get(getKey(url))
  const overlay = document.querySelector(`[data-url="${getKey(url)}"]`)

  if (!overlay) return false

  overlay.classList.add('is-closing')

  overlayPages.delete(getKey(url))

  setTimeout(() => overlay.remove(), 500)

  return true
}

export async function forEachOverlay(callback: (url) => any, type?) {
  const items = Array.from(overlayPages.entries())
  const sameType = items.filter(([_, value]) => !type || value?.type === type)

  const urls = sameType.map(([key]) => key)

  return urls.map(url => callback(url)).filter(Boolean)
}

window.addEventListener('popstate', e => {
  const last = e.state.states.length - 1
  const lastState = e.state.states[last]

  state.noop = openedOverlays.length > 0

  const topOverlay = openedOverlays.pop()

  if (lastState.data?.overlay) {
    openPage(lastState.url, {
      isNavigatingBack: true,
      pushToNavigationHistory: false,
      type: lastState.data.type,
      className: lastState.data.className,
    })
  } else {
    removeOverlay(getKey(topOverlay))
  }
})
