import 'vite/modulepreload-polyfill'
import barba from '@barba/core'
import {
  useHydrate,
  useRefs,
  useTransition,
  createDevGrid,
  createBarbaScrollPersist,
  setTransition,
  dolphin,
} from '~/framework'
import * as pageTransitions from '~/transitions'
import { globals, components } from '~/components'
import { removeOverlay } from '@/modules/overlay-controller'
import { state } from '@/utils'

dolphin()

// visit ?grid to show visual grid
createDevGrid({
  color: '0 100% 50%',
  cols: 12,
  bleed: 'var(--grid-bleed, 0px)',
  gap: 'var(--grid-gap, 2px)',
})

// persist scroll position across navigation
createBarbaScrollPersist({
  top: 'calc(var(--header-height) + var(--announcement-bar-height))',
})

let refs = useRefs()
const globalComponents = useHydrate(globals)
const pageComponents = useHydrate(components)

// used for removing all "old" event listeners after page shift
// note: this is maybe only necessary when listening on window-events?
//       browsers seems to be smart enough to removeEventListeners automatically
//       when an element is removed from the dom(?)
let abortController = null
const refreshAbortController = () => {
  abortController?.abort()
  abortController = new AbortController()
}

barba.init({
  debug: location.origin.includes('127.0.0.1'),
  prevent: () => (window as any).Shopify.designMode,
  prefetchIgnore: '/cart',
  cacheIgnore: '/cart',
  preventRunning: true,
  timeout: 10_000,
  transitions: useTransition({
    page: pageTransitions,
    global: {
      once() {
        refreshAbortController()
        globalComponents.hydrate(refs)
        pageComponents.hydrate(refs, { signal: abortController.signal })
      },
      before({ trigger }) {
        document.body.classList.add('is-transitioning')

        if (state.noop && ['popstate', 'back', 'forward'].includes(trigger)) {
          setTransition('noop')
        }

        window.dispatchEvent(new CustomEvent('se-event.window.navigation'))

        removeOverlay('/cart')
        refreshAbortController()
      },

      enter({ current, next }) {
        if (state.noop || !document.body.contains(next.container)) {
          state.noop = false
          return
        }

        refs = useRefs({ exclude: current.container })
        pageComponents.hydrate(refs, { signal: abortController.signal })
        state.noop = false
      },
      after() {
        document.body.classList.remove('is-transitioning')
      },
    },
  }),
})
