export default {
  // change barba's reference of what's the old- and new page,
  // making the old page stay in the place it was. i.e "nothing" happens
  beforeEnter({ current, next }) {
    const originalNextContainer = next.container

    current.container = document.createElement('div')
    next.container = document.createElement('div')

    originalNextContainer.remove()
  },
}
