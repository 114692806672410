import { useHydrate, useRefs } from '@/framework'
import { Component } from '@/types'
import { debounce } from '@/utils'
import productCardOverlay from './product-card-overlay'
import quickBuy from './quick-buy'
import productForm from './product-form'

const fetchPredictiveResults = debounce(
  async ({ url, q }, { signal }): Promise<HTMLElement> => {
    const params = new URLSearchParams()
    params.set('q', q)
    params.set('section_id', 'predictive-search-results')

    const res = await fetch(url + '?' + params, { signal })
    const text = await res.text()
    const parser = new DOMParser()
    const doc = parser.parseFromString(text, 'text/html')

    return doc.querySelector<HTMLElement>('.predictive-search-results')
  },
  100
)

const component: Component = (ref, { signal } = {}) => {
  if (!ref.predictiveSearch) return

  ref.predictiveSearch.map(element => {
    let refs = null
    const hydrator = useHydrate([productCardOverlay, quickBuy, productForm])

    // focus the input when opening the search
    window.addEventListener(
      'se-event.expander.expanded',
      ({ detail }: CustomEvent) => {
        const { element: openedElement, state } = detail
        if (openedElement === '.predictive-search' && state === 'open') {
          console.log(element.querySelector<HTMLInputElement>('#predictive-search'))
          element.querySelector<HTMLInputElement>('#predictive-search')?.focus()
        }
      },
      { signal }
    )

    const form = element.querySelector('form')
    const resultsContainer = element.querySelector('[data-results]')
    const { url } = element.dataset

    form.addEventListener('input', async () => {
      const data = new FormData(form)
      const q = data.get('q')

      if (!q) return

      try {
        const results = (await fetchPredictiveResults({
          url,
          q,
        })) as HTMLElement

        resultsContainer.innerHTML = ''
        resultsContainer.append(results)

        refs = hydrator.hydrate(useRefs({ root: results }))
      } catch (error) {
        console.warn(error)
      }
    })
  })
}

export default component
