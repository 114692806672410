import { Component } from '@/types'
import barba from '@barba/core'

const component: Component = ref => {
  if (!ref.collectionLayoutChanger) return

  ref.collectionLayoutChanger.forEach(form => {
    const inputs = form.querySelectorAll('input')

    function updateDOM(layout) {
      ref.main[0].classList.toggle('layout-2', layout === '2')
      ref.main[0].classList.toggle('layout-3', layout === '3')

      inputs.forEach(input => {
        input.checked = input.value === layout
      })
    }

    // initial load
    const url = new URL(window.location.href)
    const initLayout = url.searchParams.get('layout')

    updateDOM(initLayout || '1')

    form.addEventListener('change', e => {
      const input = e.target as HTMLInputElement
      // update url
      const url = new URL(window.location.href)
      if (input.value === '1') {
        url.searchParams.delete('layout')
      } else {
        url.searchParams.set('layout', input.value)
      }
      barba.history.add(url.toString(), 'barba', 'replace')

      updateDOM(input.value)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })
  })
}

export default component
