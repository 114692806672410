import { Component } from '~/types'

/**
 * so far only tested with a full screen width scroll container
 */

const component: Component = ref => {
  if (!ref.scrollDots) return

  ref.scrollDots.forEach((element: HTMLElement) => {
    const amount = element.children.length

    if(amount <= 1) return

    const container = document.createElement('div')
    container.classList.add('scroll-dots')
    const content = document.createElement('div')
    content.classList.add('scroll-dots-css-target')
    container.append(content)

    let dots = []

    for (let _ of element.children) {
      const dot = document.createElement('span')
      content.append(dot)
      dots.push(dot)
    }

    element.insertAdjacentElement('afterbegin', container)

    let currentlyActive = 0

    updateDots()

    element.addEventListener('scroll', () => {
      const { innerWidth } = window
      const { scrollLeft, scrollWidth } = element
      const scrollProgress = scrollLeft / (scrollWidth - innerWidth)
      const active = Math.round(scrollProgress * (amount - 1))

      if(currentlyActive !== active) {
        currentlyActive = active
        updateDots()
      }
    })

    function updateDots() {
      dots.forEach((dot, idx) => {
        dot.classList.toggle('active', idx === currentlyActive)
      })
    }
  })
}

export default component
