const durationOut = 400
const durationIn = 400
// const duration = 40000

export default {
  async leave({ current }) {
    return new Promise((resolve) => {
      current.container.style.transition = `all ${durationOut}ms cubic-bezier(0.25, 1, 0.5, 1)`
      current.container.style.opacity = 0
      current.container.style.translate = '0 20px'
      
      setTimeout(resolve, durationIn)
    })
  },
  
  async enter({ next }) {
    return new Promise((resolve) => {
      next.container.style.opacity = 0
      next.container.style.translate = '0 -20px'
      
      setTimeout(() => {
        next.container.style.transition = `all ${durationIn}ms cubic-bezier(0.25, 1, 0.5, 1)`
        next.container.style.opacity = 1
        next.container.style.translate = '0 0'

        setTimeout(resolve, durationIn)
      }, durationIn * 0.5)
    })
  },
}