import { useHydrate, useRefs } from '~/framework'
import { fetchPLP } from '~/utils'
import productCart from './product-card-overlay'
import productForm from './product-form'
import expander from './expander'
import imageFadeIn from './image-fade-in'
import { Component } from '@/types'

const PAGE_SIZE = 12

const component: Component = ref => {
  if (!ref.infiniteLoader) return

  function callback(entries, observer) {
    entries.forEach(async entry => {
      if (!entry.isIntersecting) return

      const searchParams = new URLSearchParams(window.location.search)

      if (searchParams.get('layout') === '3') {
        return
      }

      entry.target.dataset.page =
        entry.target.dataset.page === undefined
          ? entry.target.dataset.initialPage || '2'
          : parseInt(entry.target.dataset.page) + 1

      searchParams.set('page', entry.target.dataset.page)
      searchParams.set('limit', PAGE_SIZE.toString())

      const { plp } = await fetchPLP(
        entry.target.href + '?' + searchParams.toString()
      )

      if (!plp) return

      const items = plp.querySelectorAll('li')
      const newPageHasProducts = items?.[0].querySelector('a')

      if (newPageHasProducts) {
        useHydrate([productCart, expander, imageFadeIn, productForm]).hydrate(
          useRefs({ root: plp })
        )

        const target = ref.plp.find(
          element =>
            element.dataset.collection ===
            entry.target.dataset.infiniteLoadHandle
        )

        if (items[0].dataset.hasNextPage === undefined) {
          entry.target.classList.add('done')
        }

        target.append(...items)
      }
    })
  }

  const observer = new IntersectionObserver(callback)

  ref.infiniteLoader.forEach(element => {
    observer.observe(element)
  })

  function resetInfiniteLoaders() {
    ref.infiniteLoader.forEach(element => {
      element.removeAttribute('data-page')
    })
  }

  window.addEventListener('se-event.filter.updated', resetInfiniteLoaders)

  return () => {
    observer.disconnect()
  }
}

export default component
