import { Component } from '@/types';

export const component: Component = ref => {
  if (!ref.productPrice) return

  function checkVariant() {
    // get the variant id from the url
    const urlParams = new URLSearchParams(window.location.search);
    const id =  urlParams.get('variant');

    if(!id) return
    
    // if variant id exists in the url set update the price (used for gift cards)
    updatePrice({ detail: id })
  }
  
  const updatePrice = async(e) => {
    let productData = null
    
    // get the product data
    const res = await fetch(window.location.pathname + '.js')
    productData = await res.json()

    // check if all variants have the same price
    const allPrices = productData.variants.map(variant => variant.price);
    const arePricesSame = allPrices.every(price => price === allPrices[0]);

    // exit if all variant prices are the same
    if (arePricesSame) return;

    // find the current variant
    const variant =  productData.variants.find(product => product.id == e.detail)
    const priceEl = document.querySelector('[data-ref="product-price"]')
    // get the price and format to fit the template 50000 -> 500.00 -> 500
    const price = (variant?.price / 100).toFixed(2).replace('.00', '')
    // get the currency name from the product template
    const currency = priceEl?.dataset.currency
    
    // replace the innerHTML with price and currency
    priceEl.innerHTML = price +' '+currency
  }

  checkVariant()
  window.addEventListener('se-event.product.variant-updated', updatePrice)
}

export default component
