// taken from https://codepen.io/chriscoyier/pen/XWNqxyY

import { Component } from '~/types'

const component: Component = ref => {
  if (!ref.accordion) return

  ref.accordion.forEach((el: HTMLDetailsElement) => {
    const summary = el.querySelector('summary')
    const content = el.querySelector('.content') as HTMLElement

    let animation = null
    let isClosing = false
    let isExpanding = false

    summary.addEventListener('click', onClick)

    function onClick(e) {
      e.preventDefault()

      el.style.overflow = 'hidden'

      if (isClosing || !el.open) {
        open()
      } else if (isExpanding || el.open) {
        shrink()
      }
    }

    function shrink() {
      isClosing = true

      const startHeight = `${el.offsetHeight}px`
      const endHeight = `${summary.offsetHeight}px`

      if (animation) animation.cancel()

      animation = el.animate(
        { height: [startHeight, endHeight] },
        { duration: 200, easing: 'ease-in-out' }
      )

      animation.onfinish = () => onAnimationFinish(false)
      animation.oncancel = () => (isClosing = false)
    }

    function open() {
      el.style.height = `${el.offsetHeight}px`
      el.open = true
      window.requestAnimationFrame(expand)
    }

    function expand() {
      isExpanding = true
      const startHeight = `${el.offsetHeight}px`
      const endHeight = `${summary.offsetHeight + content.offsetHeight}px`

      if (animation) animation.cancel()

      animation = el.animate(
        { height: [startHeight, endHeight] },
        { duration: 200, easing: 'ease-in-out' }
      )

      animation.onfinish = () => onAnimationFinish(true)
      animation.oncancel = () => (isExpanding = false)
    }

    function onAnimationFinish(open) {
      el.open = open
      animation = null
      isClosing = false
      isExpanding = false

      el.style.height = el.style.overflow = ''
    }
  })
}

export default component
