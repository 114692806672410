import { Component } from '@/types'
import { openPage, prefetchPage } from '~/modules/overlay-controller'

const component: Component = ref => {
  if (!ref.productCard) return

  ref.productCard.forEach((card: HTMLLinkElement) => {
    card.addEventListener('pointerover', async () => {
      prefetchPage(card.href)
    })

    card.addEventListener('click', async e => {
      e.preventDefault()
      e.stopPropagation()

      window.dispatchEvent(new CustomEvent('se-event.product-card.overlay-opened'))

      openPage(card.href, { className: 'pdp-overlay', type: 'pdp' })
    })
  })
}

export default component
