// for all components that needs to define/know if there're any overlays opened
export const state = { noop: false }

export const isTouchDevice = 'ontouchstart' in document.documentElement

export function closeMegaMenus() {
  document.querySelectorAll('.mega-menu aside').forEach(menu => {
    menu.classList.remove('open')
  })
}

export function createFilterURL(values: FormData, options?): URL {
  const baseURL = options?.baseURL || window.location.pathname
  // const limit = options?.limit || 1000

  const url = new URL(window.location.origin + baseURL)

  for (let [key, value] of values) {
    
    url.searchParams.append(key, value.toString())
  }

  // url.searchParams.set('limit', limit.toString())

  return url
}

export async function fetchPLP(
  url,
  {
    plpSelector = '[data-ref="plp"]',
    filterSelector = '[data-ref="filter"]',
  } = {}
) {
  const res = await fetch(url)
  const text = await res.text()
  const parser = new DOMParser()
  const doc = parser.parseFromString(text, 'text/html')
  const plp = doc.querySelector(plpSelector) as HTMLElement
  const filter = doc.querySelector(filterSelector) as HTMLElement

  return { filter, plp }
}

/**
 * calls the passed function if not called again within [delay] ms.
 * an abortcontroller is passed to the callback, good for aborting a fetch if a new fetch is called
 */
export function debounce(
  callback: (arg: any, obj: { signal: AbortSignal }) => any,
  delay: number
): (arg: any) => Promise<any | Error> {
  let abortController = null

  return arg =>
    new Promise((resolve, reject) => {
      // abort any previous call to debounce
      abortController?.abort('debounced')
      abortController = new AbortController()

      const handleAbort = () => {
        clearTimeout(timeout)
        reject('debounced')
      }

      let timeout = setTimeout(async () => {
        try {
          // no new calls to debounce was made,
          // cleanup the current abort controller
          abortController.signal?.removeEventListener('abort', handleAbort)
          // run the callback!
          resolve(await callback(arg, { signal: abortController.signal }))
        } catch (error) {
          handleAbort()
        }
      }, delay)

      // cancel pending callback, and reject the previous promise
      abortController.signal?.addEventListener('abort', handleAbort)
    })
}
