import barba from '@barba/core'
import { useHydrate, useRefs } from '~/framework'
import { components } from '.'
import { createFilterURL, fetchPLP } from '~/utils'
import { isLoading } from './loading-indicator'
import { Component } from '@/types'

/**
 * to be used on a single collection page
 */

const component: Component = ref => {
  if (!ref.filter) return

  // maybe this helps the browser garbage collection? haven't tested yet..
  let refs = null

  ref.filterClearButton.forEach(button => {
    button.addEventListener('click', () => {
      ;(window as any).navigationRefresh = true
    })
  })

  async function handleFilterChange(e) {
    refs = null

    const form = e.target.closest('form')
    const url = createFilterURL(new FormData(form)).toString()

    // update browser url, to simulate page shift
    barba.history.add(url, 'barba', 'replace')
    window.scrollTo({ top: 0, behavior: 'smooth' })

    // indicate to user that stuff's happening
    isLoading('plp', true)

    // fetch new (filtered) dom
    const { filter: newFilter, plp: newPLP } = await fetchPLP(url)

    isLoading('plp', false)

    // append new dom
    ref.filter?.forEach(filter => {
      filter.innerHTML = newFilter.innerHTML

      const filterRefs = useRefs({ root: filter })
      useHydrate(components).hydrate(filterRefs)

      refs = { ...(refs || {}), filterRefs }
    })

    ref.plp?.forEach(plp => {
      plp.innerHTML = newPLP.innerHTML

      const plpRefs = useRefs({ root: plp })
      useHydrate(components).hydrate(plpRefs)

      refs = { ...(refs || {}), plpRefs }
    })
  }

  // listen for filter updates
  ref.filter.forEach(filter => {
    filter.addEventListener('change', handleFilterChange)
  })

  return () => {
    refs = null
    isLoading('plp', false)
  }
}

export default component
