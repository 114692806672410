import { useHydrate, useRefs } from '@/framework'
import productCard from './product-card-overlay'
import quickBuy from './quick-buy'
import productForm from './product-form'
import carousel from './carousel'
import imageFadeIn from './image-fade-in'
import { Component } from '@/types'

const component: Component = ref => {
  if (!ref.productRecommendations) return

  const hydrator = useHydrate([
    productCard,
    quickBuy,
    productForm,
    carousel,
    imageFadeIn,
  ])
  let refs = null

  ref.productRecommendations.forEach(async element => {
    const { url, sectionId, productId, limit, type } = element.dataset

    const fetchUrl = new URL(window.location.origin + url)

    fetchUrl.searchParams.set('section_id', sectionId)
    fetchUrl.searchParams.set('product_id', productId)
    fetchUrl.searchParams.set('limit', limit)
    fetchUrl.searchParams.set('intent', type)

    const res = await fetch(fetchUrl.toString())
    const text = await res.text()
    const parser = new DOMParser()
    const doc = parser.parseFromString(text, 'text/html')
    const markup = doc.querySelector('.product-recommendations')

    element.insertAdjacentHTML('beforeend', markup.innerHTML)

    refs = hydrator.hydrate(useRefs({ root: element }))

    element.classList.add('loaded')
  })

  return () => {
    hydrator.dehydrate()
    refs = null
  }
}

export default component
