import { Component } from '@/types'

export const component: Component = ref => {
  if (!ref.announcementBar) return

  const mod = (n, modulo) => ((n % modulo) + modulo) % modulo

  const [bar] = ref.announcementBar
  const header = bar.closest('.announcement-bar-translator') as HTMLElement
  const marquee = bar.querySelector('.marquee') as HTMLElement

  let maxY = 0
  let itemWidth = 0

  const maxSpeed = bar.dataset.noop !== undefined ? 0 : 0.5

  window.addEventListener('resize', getSize)

  bar.addEventListener('pointerover', () => (speed = 0))
  bar.addEventListener('pointerleave', () => (speed = maxSpeed))

  function getSize() {
    const { height } = bar.parentElement.getBoundingClientRect()
    const { width } = marquee.firstElementChild.getBoundingClientRect()
    document.body.style.setProperty('--announcement-bar-height', height + 'px')
    maxY = height
    itemWidth = width
  }

  getSize()

  let running = true
  let progress = 0
  let speed = maxSpeed

  // keeps the loop to 60fps
  const tick = (t1: number) => (t2: number) => {
    if (!running) return

    // move announcement bar in/out of viewport on scroll
    const value = Math.min(0, Math.min(window.scrollY, maxY) * -1)
    header.style.transform = `translateY(${Math.min(0, value)}px)`

    if (t2 - t1 > 16.6) {
      requestAnimationFrame(tick(t2))

      progress -= speed

      if (maxSpeed !== 0) {
        marquee.style.transform = `translateX(${
          -itemWidth + mod(progress, itemWidth)
        }px)`
      }
    } else {
      requestAnimationFrame(tick(t1))
    }
  }

  tick(0)(0)

  return () => {
    running = false
  }
}

export default component
