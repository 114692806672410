import { Component } from '@/types'

const component: Component = ref => {
  if (!ref.quantitySelector) return

  ref.quantitySelector.forEach(element => {
    const decreaseButton = element.querySelector('[data-decrease]')
    const increaseButton = element.querySelector('[data-increase]')
    const input = element.querySelector('input') as HTMLInputElement

    decreaseButton.addEventListener('click', () => {
      input.value = Math.max(0, parseFloat(input.value) - 1).toString()
      input.dispatchEvent(new Event('change', { bubbles: true }))
    })

    increaseButton.addEventListener('click', () => {
      input.value = (parseFloat(input.value) + 1).toString()
      input.dispatchEvent(new Event('change', { bubbles: true }))
    })
  })
}

export default component
