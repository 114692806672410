import { Component } from '@/types'
import { useRefs } from '../framework'

const component: Component = ref => {
  if (!ref.rangeInput) return

  const refs = ref.rangeInput.map((element: HTMLElement) => {
    const ref = useRefs({ root: element })

    function handleUpdate(e?) {
      if (
        e?.target.classList.contains('low') &&
        e.target.value >= parseInt(ref.inputHigh[0].value)
      ) {
        e.target.value = parseInt(ref.inputHigh[0].value)
        return
      }

      if (
        e?.target.classList.contains('high') &&
        e.target.value <= parseInt(ref.inputLow[0].value)
      ) {
        e.target.value = parseInt(ref.inputLow[0].value)
        return
      }

      ref.outputLow[0].textContent = ref.inputLow[0].value + ' ' + element.dataset.suffix
      ref.outputHigh[0].textContent = ref.inputHigh[0].value + ' ' + element.dataset.suffix
    }

    ref.inputLow[0].addEventListener('input', handleUpdate)
    ref.inputHigh[0].addEventListener('input', handleUpdate)

    handleUpdate()

    return ref
  })

  return () => {
    refs.forEach(ref => (ref = null))
  }
}

export default component
