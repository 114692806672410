import { Component } from '@/types'

/**
 * <button data-ref="expander" data-expands=".some-selector"></button>
 * <div class="some-selector">I'm expandable</div>
 */

const component: Component = ref => {
  if (!ref.expander) return

  const teardowns = ref.expander.map(element => {
    const expands = document.querySelectorAll(element.dataset.expands)

    if (!expands.length) {
      console.log(`expander didn't find element:`, element.dataset.expands)
      return
    }

    function closeExpandersInGroup(element) {
      const groupName = element.dataset.expandsUnique
      const siblingsToClose = document.querySelectorAll(
        `[data-expands-unique=${groupName}]`
      )

      siblingsToClose.forEach((sibling: HTMLElement) => {
        if (
          element === sibling ||
          element.dataset.expands === sibling.dataset.expands
        )
          return

        // console.log(sibling, sibling.dataset.expands, document.querySelectorAll(sibling.dataset.expands))

        document
          .querySelectorAll(sibling.dataset.expands)
          .forEach(el => el.classList.remove('open'))
        sibling.classList.remove('open')
      })
    }

    function handleClick({ forceOpen }: { forceOpen?: boolean } = {}) {
      if (element.dataset.expandsUnique !== undefined) {
        closeExpandersInGroup(element)
      }

      // toggle class of the target
      expands.forEach(element => element.classList.toggle('open', forceOpen))

      ref.expander.forEach(el => {
        if (el.dataset.expands === element.dataset.expands) {
          // toggle class of the expander-button
          el.classList.toggle('open', expands[0].classList.contains('open'))
        }
      })

      // inform rest of the app that something happened
      window.dispatchEvent(
        new CustomEvent('se-event.expander.expanded', {
          detail: {
            element: element.dataset.expands,
            state: expands[0].classList.contains('open') ? 'open' : 'closed',
          },
        })
      )
    }

    const toggleOpen = () => handleClick()
    const open = () => handleClick({ forceOpen: true })
    const close = () => handleClick({ forceOpen: false })

    element.addEventListener('click', toggleOpen)

    if (element.dataset.expandsOnHover !== undefined) {
      element.addEventListener('mouseover', open)
    }

    const closeButton = document.querySelector(
      `[data-expands-close="${element.dataset.expands}"]`
    )

    closeButton?.addEventListener('click', close)

    return () => {
      element.removeEventListener('click', toggleOpen)
      element.removeEventListener('mouseover', open)

      const closeButton = document.querySelector(
        `[data-expands-close="${element.dataset.expands}"]`
      )

      closeButton?.removeEventListener('click', close)
    }
  })

  return () => {
    teardowns.forEach(teardown => teardown())

    // close everything on page shift
    ref.expander.forEach(element => {
      document
        .querySelectorAll(element.dataset.expands)
        .forEach(el => el.classList.remove('open'))
      element.classList.remove('open')
    })
  }
}

export default component
