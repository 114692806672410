import { closeAllOverlays } from '@/modules/overlay-controller'
import { Component } from '~/types'

/**
 * when the "Go to cart" link, inside the cart overlay, is clicked and a product overlay is opened.
 * Close the overlay.
 * 
 */

const component: Component = (ref, { signal = null } = {}) => {
  if (!ref.goToCartLink) return

  return ref.goToCartLink.map(link => {
    link.addEventListener('click', closeAllOverlays)

    return () => link.removeEventListener('click', closeAllOverlays)
  })
}

export default component
