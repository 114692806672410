// taken from https://codepen.io/loxks/pen/KKpVvVW

// todo: make teardown

export default slider => {
  let isDown = false
  let startX
  let scrollLeft

  const speed = 1

  // make slider work in firefox
  slider.querySelectorAll('a').forEach(a => {
    a.ondragstart = () => false
  })

  slider.addEventListener('mousedown', e => {
    isDown = true

    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
    cancelMomentumTracking()
  })

  slider.addEventListener('mouseleave', () => {
    isDown = false
    slider.classList.remove('active')
  })

  slider.addEventListener('mouseup', () => {
    isDown = false
    beginMomentumTracking()
  })

  slider.addEventListener("pointercancel", (event) => {
    event.preventDefault()
  })

  slider.addEventListener('mousemove', e => {
    if (!isDown) return

    e.preventDefault()

    if (!slider.classList.contains('active')) {
      slider.classList.add('active')
    }

    const x = e.pageX - slider.offsetLeft
    const walk = (x - startX) * speed
    var prevScrollLeft = slider.scrollLeft
    slider.scrollLeft = scrollLeft - walk
    velX = slider.scrollLeft - prevScrollLeft
  })

  // Momentum

  var velX = 0
  var momentumID

  slider.addEventListener('wheel', e => {
    cancelMomentumTracking()
  })

  function beginMomentumTracking() {
    cancelMomentumTracking()
    momentumID = requestAnimationFrame(momentumLoop)
  }

  function cancelMomentumTracking() {
    cancelAnimationFrame(momentumID)
  }

  function momentumLoop() {
    slider.scrollLeft += velX
    velX *= 0.95

    if (Math.abs(velX) > 0.5) {
      momentumID = requestAnimationFrame(momentumLoop)
    } else {
      slider.classList.remove('active')
    }
  }
}
