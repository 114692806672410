/**
 * combined filter
 *
 * works by fetching and merging multiple filters,
 * fetched from each collection's own collection page.
 *
 * Shopify only gives access to the filter-object on one collection's own page.
 * Because the site should show multiple collections on a single page,
 * we need to fetch both the filter-options and products from each collection's endpoint..
 */

import { useHydrate, useRefs } from '~/framework'
import { components } from '.'
import { createFilterURL, fetchPLP } from '~/utils'
import barba from '@barba/core'
import { isLoading } from './loading-indicator'
import productCart from './product-card-overlay'
import { Component } from '@/types'

const FILTER_SELECTOR = '.filter form'
const PRICE_SLIDER_SELECTOR = '[data-ref="range-input"]'

async function fetchFilter(url: string): Promise<HTMLElement | Error> {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(url + window.location.search)
      const text = await res.text()
      const parser = new DOMParser()
      const doc = parser.parseFromString(text, 'text/html')
      const fetchedFilter = doc.querySelector(FILTER_SELECTOR) as HTMLElement

      resolve(fetchedFilter)
    } catch (error) {
      reject(error)
    }
  })
}

function fetchAndAppendFilteredPLPs(urls: string[]) {
  const searchParams = new URLSearchParams(window.location.search)

  return Promise.allSettled(
    urls.map(async collectionUrl => {
      return new Promise(async (resolve, reject) => {
        try {
          const { plp } = await fetchPLP(
            collectionUrl + '?' + searchParams.toString()
          )

          const target = document.querySelector<HTMLElement>(
            `[data-collection-plp="${collectionUrl}"] ul`
          )

          const parent = target?.closest(
            '.collection-list-layout-item'
          ) as HTMLElement

          if (!plp.querySelector('.product-card')) {
            if (parent) parent.style.display = 'none'

            const title = parent?.parentElement
              .previousElementSibling as HTMLElement
            if (title) title.style.display = 'none'
          } else {
            if (target?.dataset.lazy == 'true') {
              resolve('success')
              return
            }

            if (target) target.innerHTML = plp.innerHTML
            if (parent) parent.style.removeProperty('display')

            const title = parent?.parentElement
              .previousElementSibling as HTMLElement
            if (title) title.style.removeProperty('display')
          }

          resolve('success')
        } catch (error) {
          // window.location.reload()
          console.log(error)
          reject()
        }
      })
    })
  )
}

function getMaxPriceOfAllForms(forms: HTMLFormElement[]) {
  return forms
    .map(form => form?.querySelector(PRICE_SLIDER_SELECTOR) as HTMLInputElement)
    .reduce((acc, cur) => {
      const maxValue = cur?.querySelector('input').max
      return !maxValue ? acc : Math.max(acc, parseInt(maxValue))
    }, 0)
}

function updatePriceRangeValues(forms, targetForm) {
  if (!targetForm) return

  const currentMaxPriceInURL = new URLSearchParams(window.location.href)
  const maxPrice = getMaxPriceOfAllForms(forms)
  const priceSlider = targetForm.querySelector(PRICE_SLIDER_SELECTOR)

  if (!priceSlider) return

  priceSlider.querySelectorAll('input').forEach(input => (input.max = maxPrice))

  const urlMaxPrice = currentMaxPriceInURL.get('filter.v.price.lte')
  const maxPriceInput = priceSlider.querySelector('.input.high')

  maxPriceInput.value = urlMaxPrice || maxPrice
}

const component: Component = async ref => {
  if (!ref.combinedFilter) return

  // save dom-elements "globally", for garbage collection
  let filterRefs = null
  const filterComponents = useHydrate(components)

  let plpRefs = null
  const plpComponents = useHydrate(productCart)

  ref.combinedFilter.forEach(async filter => {
    // the combined form where all unique facets and inputs will be appended to
    const targetForm = filter.querySelector('form')
    const facetContainer = targetForm.querySelector(
      '[data-ref="combined-filter-facets"]'
    )

    // all the urls for all collection to gather filters from
    const urls = filter.dataset.collectionUrls.split(';').filter(Boolean)

    isLoading('combinedFilter', true)

    // fetch all individual forms for all collections
    const formPromises = await Promise.allSettled(urls.map(fetchFilter))

    isLoading('combinedFilter', false)

    // just save the one we manage to fetch
    const successfulForms: HTMLFormElement[] = formPromises
      .filter(formPromise => formPromise.status === 'fulfilled')
      .map(formPromise => formPromise.value)

    // loop through all fetched forms
    successfulForms.forEach(form => {
      const sections: NodeListOf<HTMLElement> =
        form?.querySelectorAll('[data-filter-type]') || []

      // loop through all type of facets in the form
      sections.forEach(section => {
        const handleSelector = `[data-filter-handle="${section.dataset.filterHandle}"]`

        // new type of facet -> append it!
        if (!targetForm.querySelector(handleSelector)) {
          facetContainer.append(section)
        }

        const item: HTMLElement = section.querySelector('[data-filter-items]')

        if (!item) return

        const newSection = targetForm
          .querySelector(handleSelector)
          .querySelector('[data-filter-items]')

        const inputs = [...item.children] as HTMLElement[]

        // loop through all values in the fetched form
        inputs.forEach(input => {
          const inputValue = input.querySelector('input').value

          // if this is an input with a value yet not in the combined facet -> append it!
          if (!newSection.querySelector(`input[value="${inputValue}"]`)) {
            newSection.append(input)
          }
        })

        // sort the inputs in the newSection based on the order array
        const newSectionInputs = [...newSection.children] as HTMLElement[]

        if (['storrelse', 'size'].includes(section.dataset.filterHandle)) {
          newSectionInputs.sort((a, b) => {
            const order = [
              'ONESIZE',
              'XS/S',
              'S/M',
              'M/L',
              'L/XL',
              '1-2 Y',
              '1-3 Y',
              '3-4 Y',
              '3-5 Y',
              '4-6 Y',
              '5-6 Y',
              '5-7 Y',
              '7-8 Y',
              '7-9 Y',
            ]

            const valueA = a.querySelector('input').value
            const valueB = b.querySelector('input').value

            // get the index in the order array; if not found, use a large index
            const indexA =
              order.indexOf(valueA) === -1 ? Infinity : order.indexOf(valueA)
            const indexB =
              order.indexOf(valueB) === -1 ? Infinity : order.indexOf(valueB)

            return indexA - indexB
          })

          // re-append the sorted inputs back into the newSection
          newSectionInputs.forEach(input => newSection.append(input))
        }

        /**
         * reorder material-tags options alphabetically
         */
        if (['material'].includes(section.dataset.filterHandle)) {
          const alphabetically = items => {
            return items.sort((a, b) => {
              return a.textContent.localeCompare(b.textContent)
            })
          }

          const orderedDomElements = alphabetically(newSectionInputs)

          orderedDomElements.forEach(input => newSection.append(input))
        }
      })
    })

    // adjust the price range slider to the minimum..maximum of all fetched forms
    updatePriceRangeValues(successfulForms, targetForm)

    // hydrate the new and combined form (primarily the price slider that needs js)
    filterRefs = filterComponents.hydrate(
      useRefs({ root: facetContainer as HTMLElement })
    )

    targetForm.addEventListener('change', async e => {
      const form = (e.target as HTMLElement).closest('form')

      const url = createFilterURL(new FormData(form)).toString()

      // update browser url, to simulate page shift
      barba.history.add(url, 'barba', 'replace')
      window.scrollTo({ top: 0, behavior: 'smooth' })

      document
        .querySelectorAll('.collection-list-layout-item')
        .forEach(scrollContainer => {
          scrollContainer.scrollTo({ left: 0, behavior: 'smooth' })
        })

      isLoading('combinedPLPs', true)

      // update all plp's, await all before moving on
      await fetchAndAppendFilteredPLPs(urls)

      isLoading('combinedPLPs', false)

      window.dispatchEvent(new CustomEvent('se-event.filter.updated'))

      plpRefs = plpComponents.hydrate(
        useRefs({ root: ref.combinedFilterResults[0] })
      )
    })

    /**
     * perform filtering on page load, if the url contains filter-params
     */
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search)

      searchParams.delete('layout') // we don't care about this one

      const hasNonEmptySearchParam = [...searchParams.values()].find(Boolean)

      if (hasNonEmptySearchParam) {
        isLoading('combinedPLPs', true)

        await fetchAndAppendFilteredPLPs(urls)
        plpRefs = plpComponents.hydrate(
          useRefs({ root: ref.combinedFilterResults[0] })
        )

        isLoading('combinedPLPs', false)
      }
    }
  })

  return () => {
    filterRefs = null
    plpRefs = null
    isLoading('combinedPLPs', false)
    isLoading('combinedFilter', false)
  }
}

export default component
