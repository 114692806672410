import accordion from './accordion'
import announcementBar from './announcement-bar'
import carousel from './carousel'
import collectionLayoutChanger from './collection-layout-changer'
import combinedFilter from './combined-filter'
import expander from './expander'
import filter from './filter'
import imageFadeIn from './image-fade-in'
import inView from './in-view'
import infiniteLoader from './infinite-loader'
import links from './links'
import loadingIndicator from './loading-indicator'
import mainCart, { cartItemCount, cartLink } from './main-cart'
import mainMenu from './main-menu'
import { mainSearchForm, mainSearchResults } from './main-search'
import pageNavigation from './page-navigation'
import preOrderPopup from './pre-order-popup'
import predictiveSearch from './predictive-search'
import productCardOverlay from './product-card-overlay'
import productForm from './product-form'
import productRecommendation from './product-recommendation'
import productVariantPrice from './product-variant-price'
import quantitySelector from './quantity-selector'
import quickBuy from './quick-buy'
import rangeInput from './range-input'
import scrollDots from './scroll-dots'
import goToCartLink from './go-to-cart-link'
import customSelect from './custom-select'

// add all components that only needs to be initialized once
export const globals = [
  cartLink,
  cartItemCount,
  loadingIndicator,
  mainMenu,
  quickBuy,
  predictiveSearch,
  announcementBar,
]

// add all components that needs to be initialized after every page shift
export const components = [
  accordion,
  carousel,
  collectionLayoutChanger,
  combinedFilter,
  expander,
  filter,
  imageFadeIn,
  infiniteLoader,
  inView,
  links,
  mainCart,
  mainSearchForm,
  mainSearchResults,
  productCardOverlay,
  productForm,
  productRecommendation,
  quantitySelector,
  rangeInput,
  scrollDots,
  pageNavigation,
  productVariantPrice,
  preOrderPopup,
  goToCartLink,
  customSelect
]
