/**
 * todo: will mess up if element-ref is removed from dom before it noticed that it's finished loading
 */

import { Component } from '@/types'

// example:
// isLoading('my-component', true)
// const res = await fetch(url)
// isLoading('my-component', false)

export function isLoading(ref, isLoading) {
  window.dispatchEvent(
    new CustomEvent('se-event.window.is-loading', {
      detail: { ref, isLoading },
    })
  )
}

const component: Component = ref => {
  if (!ref.loadingIndicator) return

  let currentlyLoading = new Set()

  function updateGlobalDOM() {
    const isLoading = currentlyLoading.size > 0
    document.body.classList.toggle('is-loading', isLoading)
    document.body.setAttribute('aria-busy', isLoading.toString())
  }

  window.addEventListener(
    'se-event.window.is-loading',
    ({ detail: { ref, isLoading } }: CustomEvent) => {
      if (isLoading) {
        currentlyLoading.add(ref)
      } else {
        currentlyLoading.delete(ref)
      }

      updateGlobalDOM()
    }
  )

  return () => {
    currentlyLoading.clear()
  }
}

export default component
