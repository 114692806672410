import { useHydrate, useRefs } from '@/framework'
import { Component } from '@/types'
import { debounce } from '@/utils'
import barba from '@barba/core'
import { components } from '.'
import { isLoading } from './loading-indicator'

const performSearch: (args: {
  searchUrl: string
  values: { [key: string]: FormDataEntryValue | string }
}) => Promise<Document> = debounce(
  async ({ searchUrl, values }, { signal }) => {
    const url = new URL(window.location.origin + '/' + searchUrl)

    for (let value in values) {
      url.searchParams.set(value, values[value])
    }

    const res = await fetch(url.toString(), { signal })
    const text = await res.text()
    const parser = new DOMParser()
    const doc = parser.parseFromString(text, 'text/html')

    return doc
  },
  200
)

const mainSearchForm: Component = (ref, { signal } = {}) => {
  if (!ref.mainSearchForm) return

  async function performDebouncedSearch(e, form) {
    e.preventDefault()
    e.stopPropagation()

    const data = new FormData(form)
    const values = Object.fromEntries(data.entries())
    const url = new URL(window.location.href)

    url.searchParams.set('q', values.q.toString())

    barba.history.add(url.href, 'popstate', 'replace')

    isLoading('main-search', true)

    try {
      const result = await performSearch({
        searchUrl: form.dataset.url,
        values,
      })

      window.dispatchEvent(
        new CustomEvent('ss-event.search.performed', { detail: result })
      )
    } catch (error) {
      console.log(error)
    }

    isLoading('main-search', false)
  }

  ref.mainSearchForm.forEach((form: HTMLFormElement) => {
    form.addEventListener('input', e => performDebouncedSearch(e, form))
    form.addEventListener('submit', e => performDebouncedSearch(e, form))
  })
}

const mainSearchResults: Component = (ref, { signal } = {}) => {
  if (!ref.mainSearchResults) return

  let refs = null
  const hydrator = useHydrate(components)

  function updateResultsList(e) {
    const { detail: newResultsDocument } = e

    ref.mainSearchResults.forEach(element => {
      const results = newResultsDocument.querySelector(
        '[data-ref="main-search-results"]'
      )
      element.innerHTML = results.innerHTML

      refs = hydrator.hydrate(useRefs({ root: element }))
    })
  }

  window.addEventListener('ss-event.search.performed', updateResultsList)
  
  return () => {
    window.removeEventListener('ss-event.search.performed', updateResultsList)
    refs = null
  }
}

export { mainSearchForm, mainSearchResults }
