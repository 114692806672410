import { Component } from '@/types'
import { isLoading } from './loading-indicator'

// probably not needed
window.addEventListener(
  'a-la-cart.add-to-cart-loading',
  ({ detail: loadingState }: CustomEvent) => {
    isLoading('quick-buy', loadingState)
  }
)

const component: Component = () => {}

export default component
