import { Component } from "@/types";

const component: Component = (ref) => {
  if (!ref.pageNavigation) return;
  
  const activeLink = [...ref.pageNavigation][0].querySelector('.current');

  if (activeLink) {
    activeLink.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center' 
    });
  }
};

export default component;
