import { Component } from '@/types'

const inView: Component = ref => {
  if (!ref.inView) return

  function callback(entries) {
    entries.forEach(entry => {
      entry.target.classList.toggle('not-intersecting', !entry.isIntersecting)
    })
  }

  const observer = new IntersectionObserver(callback, { threshold: 0 })

  ref.inView.forEach(element => {
    observer.observe(element)
  })

  return () => {
    observer.disconnect()
  }
}

export default inView
