import { Component } from '@/types'

const component: Component = ref => {
  if (!ref.preOrderPopup) return

  ref.preOrderPopup.forEach((el: HTMLDetailsElement) => {
    const close = el.querySelector('.close')
    close?.addEventListener('click', () => {
      el.classList.add('hide')
    })
    const bg = el.querySelector('.bg')
    bg?.addEventListener('click', () => {
      el.classList.add('hide')
    })
  })
}


export default component