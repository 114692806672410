import dragScroll from "~/modules/drag-scroll";
import { Component } from "~/types";
import { isTouchDevice } from "~/utils";

/**
 * you'll probably want css something like:
    
  .element {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 50vw;
    overflow: auto;
  }
 */

const component: Component = (ref) => {
  if (!ref.carousel) return;

  ref.carousel.forEach(dragScroll);

  const triggers = [].concat(ref.showMoreTrigger);
  
  triggers.forEach((trigger: HTMLElement) => {
    
    if (!trigger) return;
    
    trigger.addEventListener("click", () => {
      const list = trigger.nextElementSibling as HTMLElement;
      const distance = isTouchDevice ? 250 : 700;

      if (list) {
        list.scrollBy({
          left: distance,
          behavior: "smooth",
        });
      }
    });
  });
};

export default component;
