import { Component } from '~/types'

const CLASSES = {
  HYDRATED: 'is-hydrated',
  LOADED: 'is-loaded',
  SEEN: 'is-seen',
}

function callback(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add(CLASSES.SEEN)
      observer.unobserve(entry.target)
    }
  })
}

const observer = new IntersectionObserver(callback, { threshold: 0 })

const component: Component = ref => {
  if (!ref.image) return

  ref.image.forEach((image: HTMLImageElement) => {
    image.classList.add(CLASSES.HYDRATED)

    observer.observe(image)

    if (image.complete) {
      image.classList.add(CLASSES.LOADED)
    } else {
      image.onload = () => {
        image.classList.add(CLASSES.LOADED)
      }
    }
  })
}

export default component
