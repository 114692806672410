import { Component } from '@/types'
import { setTransition } from '../framework'

const component: Component = () => {
  const allLinksOnThePage = document.querySelectorAll('a')

  function handleClick() {
    setTransition('defaultTransition')
  }

  allLinksOnThePage.forEach(link => {
    link.addEventListener('click', handleClick)
  })

  return () => {
    allLinksOnThePage.forEach(link => {
      link.removeEventListener('click', handleClick)
    })
  }
}


export default component