import { Component } from '@/types'
import { closeMegaMenus } from './../utils'

const component: Component = ref => {
  if (!ref.mainMenu && !ref.mainMenuToggle) return

  window.addEventListener(
    'se-event.expander.expanded',
    ({ detail }: CustomEvent) => {
      if (detail.element === '.main-menu' && detail.state === 'open') {
        ref.megaMenu[0].classList.add('open')
        ref.megaMenu[0].parentElement
          ?.querySelector('[data-ref="expander"]')
          ?.classList.add('open')
      }
    }
  )

  window.addEventListener('se-event.window.navigation', () => {
    closeMegaMenus()
  })
}

export default component
